import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/store';

import { BookingChannelTypes } from '@/constants/settings';
import {
  selectAgencyId,
  selectAgentBookingChannel,
  selectAgentId,
  selectIsAgencyDataAvailable,
} from '@/ducks/fm/selectors';
import {
  selectIsMnvv,
  selectMnvvAgencyId,
  selectMnvvAgentId,
  selectMnvvChannelId,
  selectMnvvReservationNumber,
} from '@/ducks/mnvv/selectors';

export type TAgentDetailPayload = {
  agencyId?: string;
  agentId?: string;
};

const selectAgentDetailPayload = createSelector(
  [selectIsMnvv, selectMnvvAgencyId, selectMnvvAgentId, selectAgencyId, selectAgentId],
  (isMnvv, mnvvAgencyId, mnvvAgentId, agencyId, agentId): TAgentDetailPayload => {
    const agency = isMnvv ? mnvvAgencyId : agencyId;
    const agent = isMnvv ? mnvvAgentId : agentId;

    return {
      ...(agency && { agencyId: agency }),
      ...(agent && { agentId: agent }),
    };
  },
);

export type TBookingSourcePayload = {
  agentDetail?: TAgentDetailPayload;
  bookingChannel?: string;
  reservationNumber?: string;
};

export const selectBookingSourcePayload = (state: RootState): TBookingSourcePayload => {
  // todo: create separated selector for payment
  const isSignature = false;
  const isReservation = true;

  const isMnvv = selectIsMnvv(state);
  const reservationNumber = selectMnvvReservationNumber(state);
  const mnvvChannelId = selectMnvvChannelId(state) || undefined;
  const isFm = selectIsAgencyDataAvailable(state);
  const agentBookingChannel = selectAgentBookingChannel(state);
  const agentDetail = selectAgentDetailPayload(state);

  return {
    ...(isMnvv && { bookingChannel: mnvvChannelId }),
    ...(isMnvv && isReservation && { reservationNumber }),
    ...(!isMnvv && isFm && { bookingChannel: agentBookingChannel || BookingChannelTypes.fmLink }),
    ...(!isMnvv && !isFm && { bookingChannel: BookingChannelTypes.openTravel }),
    ...(!isSignature ? isFm && { agentDetail } : { ...agentDetail }),
  };
};
