import isEmpty from 'lodash/isEmpty';

import { type TBookingSourcePayload } from '@/ducks/api/selectors';
import { isLockItInFlowCabin } from '@/ducks/programs/lockItInRate/isLockItCabin';
import { type TFetchVoyagesPayload } from '@/features/api/endpoints/voyage';
import { type TCurrencyCode } from '@/infra/types/common';

type TFetchVoyagesPreference = TFetchVoyagesPayload['searchQualifier']['preferences'][number];

export type TMakeFetchVoyagesPayloadArgs = {
  accessKey?: string;
  bookingSourcePayload?: TBookingSourcePayload;
  categoryCode?: string;
  currencyCode: TCurrencyCode;
  dateFrom: string;
  dateTo: string;
  defaultCurrencyCode: TCurrencyCode;
  defaultEndDate: string;
  defaultPackageCodes?: string[];
  defaultSailors: number;
  defaultStartDate: string;
  metaCode?: string;
  sailors: number;
};

const makeFetchVoyagesPayload = ({
  accessKey,
  bookingSourcePayload,
  categoryCode,
  currencyCode,
  dateFrom,
  dateTo,
  defaultCurrencyCode,
  defaultEndDate,
  defaultPackageCodes,
  defaultSailors,
  defaultStartDate,
  metaCode,
  sailors,
}: TMakeFetchVoyagesPayloadArgs): TFetchVoyagesPayload => {
  const preferences = makePreferences(categoryCode, metaCode);
  const isDefaultSearch =
    !accessKey &&
    !preferences?.length &&
    (!currencyCode || currencyCode === defaultCurrencyCode) &&
    (!dateFrom || dateFrom === defaultStartDate) &&
    (!dateTo || dateTo === defaultEndDate) &&
    (!sailors || sailors === defaultSailors) &&
    isEmpty(bookingSourcePayload);
  return {
    sailingsGroupByPackage: true,
    searchQualifier: {
      accessKeys: accessKey ? [accessKey] : [],
      cabins: [
        {
          guestCounts: [{ ageCategory: 'Adult', count: sailors || defaultSailors }],
        },
      ],
      currencyCode: currencyCode || defaultCurrencyCode,
      defaultPackageCodes: defaultPackageCodes || [],
      isDefaultSearch,
      preferences,
      sailingDateRange: [{ end: dateTo, start: dateFrom }],
    },
    ...bookingSourcePayload,
  };
};

const makePreferences = (categoryCode?: string, metaCode?: string): TFetchVoyagesPreference[] => {
  if (isLockItInFlowCabin(categoryCode)) return [{ categoryCode }];
  return metaCode ? [{ metaCode }] : [];
};

export default makeFetchVoyagesPayload;
