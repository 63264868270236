import { lastDayOfMonth, max, startOfMonth } from 'date-fns';
import defaults from 'lodash/defaults';
import isEqual from 'lodash/isEqual';

import { selectAccessKey } from '@/ducks/accessKeys/accessKeys';
import { selectBookingSourcePayload } from '@/ducks/api/selectors';
import { selectDefaultPackageCodes } from '@/ducks/common/lookup/selectors';
import { selectDefaultCurrencyCode } from '@/ducks/common/selectors';
import {
  selectCabinType,
  selectCurrencyCode,
  selectFromDate,
  selectMetaType,
  selectSailors,
  selectToDate,
} from '@/ducks/filters/selectors';
import {
  selectDefaultFilterSailors,
  selectFiltersOptionsDefaultEndDate,
  selectFiltersOptionsDefaultStartDate,
} from '@/ducks/filtersOptions';
import { fetchVoyages, type TFetchVoyagesPayload } from '@/features/api/endpoints/voyage';
import makeFetchVoyagesPayload, {
  type TMakeFetchVoyagesPayloadArgs,
} from '@/features/api/helpers/makeFetchVoyagesPayload';
import { type CoreRequestConfig } from '@/helpers/api/utils';
import { format, parse } from '@/helpers/util/dateUtil';
import { type TCurrencyCode, type TISODate } from '@/infra/types/common';
import { type AppDispatch, type AppGetState, type RootState } from '@/store';

import {
  selectFetchVoyagesSuccessOptions,
  selectMakeFilteredPackages,
  selectPackagesIsLoading,
  selectVoyagesApiPayload,
} from '../selectors';
import { searchFailure, searchStart, searchSuccess, setFilteredPackages } from '../slice';

type TBuildOptions = {
  accessKey?: string;
  currencyCode?: TCurrencyCode;
  dateFrom?: TISODate;
  dateTo?: TISODate;
  metaType?: string;
  sailors?: number;
};

export const makeVoyagesApiDatesOptions = (embarkDate: TISODate, debarkDate: TISODate): TBuildOptions => {
  let dateFrom = parse(embarkDate);
  if (dateFrom) dateFrom = max([startOfMonth(dateFrom), dateFrom]); // PR#7986: BE dont support past dates
  let dateTo = parse(debarkDate);
  if (dateTo) dateTo = lastDayOfMonth(dateTo);
  return {
    dateFrom: format(dateFrom),
    dateTo: format(dateTo),
  };
};

export const selectFetchVoyagesPayloadDefaults = (state: RootState): TMakeFetchVoyagesPayloadArgs => ({
  accessKey: selectAccessKey(state),
  bookingSourcePayload: selectBookingSourcePayload(state),
  categoryCode: selectCabinType(state),
  currencyCode: selectCurrencyCode(state),
  dateFrom: selectFromDate(state),
  dateTo: selectToDate(state),
  defaultCurrencyCode: selectDefaultCurrencyCode(state),
  defaultEndDate: selectFiltersOptionsDefaultEndDate(state),
  defaultPackageCodes: selectDefaultPackageCodes(state),
  defaultSailors: selectDefaultFilterSailors(state)!,
  defaultStartDate: selectFiltersOptionsDefaultStartDate(state),
  metaCode: selectMetaType(state),
  sailors: selectSailors(state),
});

export const buildFetchVoyageListDataPayload = (state: RootState, options?: TBuildOptions): TFetchVoyagesPayload =>
  makeFetchVoyagesPayload(defaults({ ...options }, selectFetchVoyagesPayloadDefaults(state)));

export const getVoyagesApiPayload =
  (options?: TBuildOptions) =>
  (_: AppDispatch, getState: AppGetState): TFetchVoyagesPayload =>
    buildFetchVoyageListDataPayload(getState(), options);

export const fetchPackages =
  (config?: CoreRequestConfig) =>
  async (dispatch: AppDispatch, getState: AppGetState): Promise<void> => {
    let state = getState();

    try {
      const apiPayload = buildFetchVoyageListDataPayload(state);
      if (isEqual(apiPayload, selectVoyagesApiPayload(state))) return;

      dispatch(searchStart(apiPayload));

      const response = await fetchVoyages({ ...config, logShortResponseData: true, payload: apiPayload });

      state = getState();
      const options = selectFetchVoyagesSuccessOptions(state);
      dispatch(searchSuccess({ ...options, response }));

      dispatch(filterPackages());
    } catch (e) {
      console.error(e);
      dispatch(searchFailure(e as Error));
    }
  };

export const filterPackages = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const isLoading = selectPackagesIsLoading(state);
  if (!isLoading) dispatch(setFilteredPackages(selectMakeFilteredPackages(state)));
};
