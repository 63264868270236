import { gql } from '@apollo/client';

export const FETCH_REFERRAL_REFERRAL_CODE_DATA = gql`
  query FetchReferralReferralCodeData($referralCode: String!) {
    fetchReferralReferralCode(referralCode: $referralCode) {
      bookingNumber
      voyageId
    }
  }
`;
