import { ApolloClient, type NormalizedCacheObject } from '@apollo/client';

import { isServerSide } from '@/helpers/isServerSide';

import makeApolloClient, { type TMakeApolloClientArgs } from './makeApolloClient';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const getApolloClient = (args?: TMakeApolloClientArgs) => {
  if (isServerSide()) {
    return makeApolloClient({ ...args, isClientOnly: false });
  }

  if (!apolloClient) apolloClient = makeApolloClient(args);
  return apolloClient;
};

export default getApolloClient;
