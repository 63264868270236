import { type TAuthHeaders, TokenType, withAuthHeader } from '@/helpers/api/tokens/helpers/withToken';
import addToSearchParams from '@/helpers/url/addToSearchParams';
import { type TWithResponseHeaders } from '@/infra/types/api';
import { type TUrlParams } from '@/infra/types/common';
import { type TOptional } from '@/types/common';

export type TQueryParams = TOptional<TUrlParams | URLSearchParams>;
export type TPayload = TOptional<Record<string, unknown>>;

export type TFetchOptions<Q extends TQueryParams = undefined, L extends TPayload = undefined> = TWithResponseHeaders &
  Omit<RequestInit, 'body'> & {
    params?: Q;
    payload?: L;
  };

const simpleFetch = async <R = unknown, Q extends TQueryParams = undefined, L extends TPayload = undefined>(
  url: string,
  options?: TFetchOptions<Q, L>,
): Promise<R> => {
  const { method, onResponseHeaders, params, payload, ...rest } = options || {};
  const searchParams = params ? addToSearchParams(new URLSearchParams(), params) : undefined;
  // Note: size property is a read only property of URLSearchParams and inconsistent as sometimes it is undefined.
  // alternate workaround can be searchParams.toString(), Fix: MSH-132437
  const queryParams = searchParams?.toString();
  const requestUrl = queryParams ? `${url}?${queryParams}` : url;
  const args = { ...(rest as RequestInit), method: method || 'GET' };
  if (method === 'POST' && payload) {
    args.body = JSON.stringify(payload);
    args.headers = { ...args.headers, 'Content-Type': 'application/json' };
  }
  const response = await fetch(requestUrl, args);
  onResponseHeaders?.(response.headers);
  if (!response.ok) throw new Error(`Call "${url}" failed: ${response.statusText}`);
  if (isJsonResponse(response)) return (await response.json()) as R;
  if (isNotEmptyResponse(response)) return (await response.text()) as R;
  return undefined as R;
};

export const isJsonResponse = (response: Response): TOptional<boolean> => {
  const type = response.headers.get('Content-Type');
  if (type) {
    const i = type.indexOf(';');
    return ['application/json', 'text/json'].includes((i !== -1 ? type.substring(0, i) : type).toLowerCase());
  }
};

export const isNotEmptyResponse = (response: Response): TOptional<boolean> => {
  const { headers, status } = response;
  if (headers.get('Content-Type')) return status === 200 || +(headers.get('Content-Length') || '') > 0;
};

export const simpleFetchWithAuth = withAuthHeader(
  TokenType.guest,
  <R = unknown, Q extends TQueryParams = undefined, L extends TPayload = undefined>(
    authHeaders: TAuthHeaders,
    url: string,
    options?: TFetchOptions<Q, L>,
  ): Promise<R> => simpleFetch<R, Q, L>(url, { ...options, headers: { ...options?.headers, ...authHeaders } }),
);

export const simpleFetchWithCmsAuth = withAuthHeader(
  TokenType.cms,
  <R = unknown, Q extends TQueryParams = undefined, L extends TPayload = undefined>(
    authHeaders: TAuthHeaders,
    url: string,
    options?: TFetchOptions<Q, L>,
  ): Promise<R> => simpleFetch<R, Q, L>(url, { ...options, headers: { ...options?.headers, ...authHeaders } }),
);

export default simpleFetch;
