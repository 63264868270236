import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import type { FilteredPackage, Package } from '@/infra/types/voyageInfo/package';
import type { TSailingData } from '@/infra/types/voyageInfo/sailing';
import type { RootState } from '@/store';
import type { TOptional } from '@/types/common';

import { selectLookupShips } from '@/ducks/common/lookup/selectors';
import { selectCruisesCommonPackagesDataPackages } from '@/ducks/cruisesCommon/selectors';
import { selectVoyageIds } from '@/ducks/filters/selectors';
import { getPackagesForFilteredSailings } from '@/ducks/pages/chooseVoyage/getters';
import {
  selectDefaultPackages,
  selectExtendedPackages,
  selectFilteredPackages,
  selectIsMultipleVoyageFilterActive,
  selectMainPackages,
  selectMainSailings,
} from '@/ducks/pages/chooseVoyage/selectors';
import { getBestPriceSailing } from '@/ducks/pages/chooseVoyage/utility';
import combineSailingData from '@/ducks/pages/summary/helpers/combineSailingData';
import { selectSummary } from '@/ducks/pages/summary/selectors';
import createMemoSelector from '@/helpers/createMemoSelector';

type TPackageType = Pick<Package, 'packageCode' | 'packageName'>;

export const selectSailingData = createMemoSelector(
  selectSummary,
  selectMainSailings,
  selectLookupShips,
  (summary, sailings, ships) =>
    combineSailingData(summary?.sailingData?.data, sailings, ships) as TOptional<TSailingData>,
);

export const selectSailingDataVoyageId = (state: RootState) => selectSailingData(state)?.voyageId;

export const selectSelectedPackage = createSelector(
  [selectCruisesCommonPackagesDataPackages, selectSailingData, (_, searchParams: URLSearchParams) => searchParams],
  (packages, sailingData, searchParams) => {
    const selectedPackageCode = searchParams.get('packageCode') ?? sailingData?.packageCode;

    return packages?.find((p) => p.packageCode === selectedPackageCode) || {};
  },
);

export const selectSelectedPackageFromMainPackages = createSelector(
  [
    selectMainPackages,
    selectFilteredPackages,
    selectDefaultPackages,
    selectExtendedPackages,
    selectSailingData,
    selectIsMultipleVoyageFilterActive,
    selectVoyageIds,
    (_, searchParams: URLSearchParams) => searchParams,
  ],
  (
    packages,
    filteredPackages,
    defaultPackages,
    extendedPackages,
    sailingData,
    isMultipleVoyageFilterActive,
    voyageIds,
    searchParams,
  ) => {
    const selectedPackageCode = searchParams.get('packageCode') ?? sailingData?.packageCode;
    const currentPackage = packages?.find((p: TPackageType) => p.packageCode === selectedPackageCode);
    let selectedPackage: FilteredPackage | undefined;

    const finder = (pack: TPackageType) => selectedPackageCode === pack.packageCode;

    if (filteredPackages.length === 0) {
      selectedPackage = (defaultPackages as FilteredPackage[])?.find(finder);
    }

    if (!selectedPackage) {
      selectedPackage = extendedPackages?.find(finder);
    }

    if (!selectedPackage) {
      selectedPackage = extendedPackages?.find(
        (pack: TPackageType) => pack.packageName === currentPackage?.packageName,
      );
    }

    if (isMultipleVoyageFilterActive && selectedPackage) {
      const filteredSailings = selectedPackage?.sailingList?.filter((sailing) => voyageIds.indexOf(sailing.id) > -1);
      return getPackagesForFilteredSailings(filteredSailings, packages)?.[0] as FilteredPackage;
    }

    return selectedPackage;
  },
);

export const selectIsSailingDataLoaded = createSelector(
  [(state) => state, selectSailingData, (_, searchParams: URLSearchParams) => searchParams],
  (state, sailingData, searchParams) => {
    const packageCode = searchParams.get('packageCode');
    const defaultVoyageId = selectBestPriceSailingByPackageCode(state, packageCode)?.id;
    const voyageId = searchParams.get('voyageId') || defaultVoyageId;
    return (sailingData?.voyageId || sailingData?.id) === voyageId && sailingData?.packageCode === packageCode;
  },
);

export const findBestPriceSailingForPackage = (packages: Package[], packageCode: string) => {
  if (packages?.length && packageCode) {
    const selectedPackage = packages?.find((item) => item.packageCode === packageCode);
    return getBestPriceSailing(selectedPackage?.sailingList);
  }
};

export const selectBestPriceSailingByPackageCode = createSelector(
  [selectMainPackages, (_: unknown, packageCode?: null | string) => packageCode],
  (mainPackages, packageCode) => findBestPriceSailingForPackage(mainPackages, packageCode!),
);

export const selectHasSailingDataFetchError = (state: RootState) =>
  !isEmpty(state.voyagePlanner.summary.sailingData.error);

export const selectIsSailingDataEmpty = (state: RootState) => isEmpty(selectSummary(state).sailingData.data);

export const selectVoyageId = createSelector(
  [
    selectBestPriceSailingByPackageCode,
    (_, packageCode) => packageCode,
    (_, _packageCode, voyageId: null | string) => voyageId,
  ],
  (defaultSailing, _packageCode, voyageId) => voyageId || defaultSailing?.id,
);
