import type { ReferralAddDataResponse, ReferralAddDataVariables } from '@/ducks/programs/celebrations/referral/types';

import { REFERRAL_ADD_DATA } from '@/ducks/programs/celebrations/referral/referral-add-data';
import getApolloClient from '@/features/apollo/getApolloClient';

export const referralAddData = async (params: ReferralAddDataVariables): Promise<boolean> => {
  const { referralCode, referralType, reservationId } = params;

  try {
    const client = getApolloClient();

    const { data } = await client.mutate<ReferralAddDataResponse>({
      mutation: REFERRAL_ADD_DATA,
      variables: {
        referralCode,
        referralType,
        reservationId,
      },
    });

    return data?.referral.message.includes('success') || false; // "Added Referral details successfully"
  } catch (error) {
    console.error('Error linking referral data:', error);
    throw error;
  }
};
