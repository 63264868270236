import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { WarningTarget } from '@/components/WarningBox';
import { selectConfigServiceCelebrationURL } from '@/ducks/common/settings';
import { memoizedEligibilityUrl } from '@/ducks/memoizedApiCalls/eligibilityUrl';
import {
  buildFetchVoyageListDataPayload,
  makeVoyagesApiDatesOptions,
} from '@/ducks/pages/chooseVoyage/actions/fetchPackages';
import { selectMainPackages, selectVoyagesApiPayload } from '@/ducks/pages/chooseVoyage/selectors';
import { fetchReferralReferralCodeData } from '@/ducks/programs/celebrations/referral/fetchReferralReferralCodeData';
import { referralAddData } from '@/ducks/programs/celebrations/referral/referralAddData';
import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { fetchSailings } from '@/features/api/endpoints/voyage';
import { fetchVoyages } from '@/features/api/endpoints/voyage';
import { clearWarning } from '@/features/warnings/slice';
import { generateExtendedShareURL } from '@/helpers/social';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import { getSessionStorageValue, removeSessionStorageValue, setSessionStorageValue } from '@/helpers/util';

import {
  CELEBRATION_EXIT_REFERRED_BOOKER_FLOW,
  CELEBRATION_FETCH_PRODUCT_SUCCESS,
  CELEBRATION_LINK_REFERRAL_CODE_FAILURE,
  CELEBRATION_LINK_REFERRAL_CODE_SUCCESS,
  CELEBRATIONS_ACTIVE_FAILURE,
  CELEBRATIONS_ACTIVE_SUCCESS,
  CELEBRATIONS_RESET,
  COMPLETE_CELEBRATION_PROGRAM,
} from './actionTypes';
import { selectCelebrations, selectCelebrationsParams } from './selectors';

export const CELEBRATION_SESSION_DATA = 'celebrationData';

export const generateReferralUrlActionForCelebrations = (params) => async (dispatch, getState) => {
  const { packageCode, reservationNumber, shipCode, voyageId } = params;
  const state = getState();
  const { referralCode: oldReferralCode, reservationId } = selectCelebrationsParams(state);
  // Do not make duplicate requests
  if (reservationId === reservationNumber) {
    return;
  }

  try {
    const res = await memoizedEligibilityUrl({ referralType: 'CELEBRATIONS', reservationId: reservationNumber });
    const { newReferral, referralCode } = res;
    const shareURL = selectConfigServiceCelebrationURL(state);
    const referralUrl = generateExtendedShareURL(shareURL, 'P', packageCode, voyageId, referralCode, shipCode, true);

    const data = {
      newReferral,
      newReferralCode: referralCode,
      packageCode,
      referralCode: oldReferralCode,
      referralUrl,
      reservationId: reservationNumber,
      shipCode,
      voyageId,
    };
    dispatch({
      payload: data,
      type: CELEBRATIONS_ACTIVE_SUCCESS,
    });
    setOrUpdateCelebrationInfoInSession(data);
  } catch (error) {
    const data = {
      packageCode,
      reservationId: reservationNumber,
      shipCode,
      voyageId,
    };
    dispatch({
      payload: data,
      type: CELEBRATIONS_ACTIVE_FAILURE,
    });
    setOrUpdateCelebrationInfoInSession(data);
  }
};

export const linkCelebrationsAction = (params) => async (dispatch, getState) => {
  const { packageCode, referralCode, reservationNumber, shipCode, voyageId } = params;
  const state = getState();
  const { newReferral } = selectCelebrationsParams(state);
  try {
    await referralAddData({
      referralCode,
      referralType: 'CELEBRATIONS',
      reservationId: reservationNumber,
    });
    dispatch({ payload: { newReferral, referralCode }, type: CELEBRATION_LINK_REFERRAL_CODE_SUCCESS });
    dispatch(
      generateReferralUrlActionForCelebrations({
        packageCode,
        reservationNumber,
        shipCode,
        voyageId,
      }),
    );
  } catch (error) {
    dispatch({ type: CELEBRATION_LINK_REFERRAL_CODE_FAILURE });
  }
};

export const checkCelebrationsReferralCodeAction = () => async (dispatch, getState) => {
  const state = getState();
  const queryParams = getCurrentSearchParams();

  const referralCode = queryParams.get('referralCode');

  const celebrationParams = referralCode
    ? {
        packageCode: queryParams.get('packageCode'),
        referralCode,
        shipCode: queryParams.get('shipCode'),
        voyageId: queryParams.get('voyageId'),
      }
    : getCelebrationsInfoSession();

  if (isEmpty(celebrationParams)) {
    dispatch({
      type: CELEBRATIONS_RESET,
    });
    return;
  }

  const isDuplicatedCheck = getIsDuplicatedCheck(selectCelebrations(state));
  if (isDuplicatedCheck) {
    return;
  }

  const isValidCode = await dispatch(validateCelebrationReferralCodeAction(celebrationParams));
  if (!isValidCode) {
    return;
  }
  await dispatch(fetchReferredBookerPackage());
};

const validateCelebrationReferralCodeAction = (queryParams) => async (dispatch, getState) => {
  const { packageCode, referralCode, shipCode, voyageId } = queryParams;
  const state = getState();
  const { referralUrl } = selectCelebrationsParams(state);
  try {
    const { fetchReferralReferralCode } = await fetchReferralReferralCodeData({ referralCode });
    if (fetchReferralReferralCode.voyageId === voyageId) {
      const data = {
        newReferral: false,
        packageCode,
        referralCode,
        referralUrl,
        reservationId: fetchReferralReferralCode.bookingNumber,
        shipCode,
        voyageId,
      };

      dispatch({
        payload: data,
        type: CELEBRATIONS_ACTIVE_SUCCESS,
      });
      setOrUpdateCelebrationInfoInSession(data);
      return true;
    } else {
      dispatch({
        payload: { newReferral: true },
        type: CELEBRATIONS_ACTIVE_FAILURE,
      });
      setOrUpdateCelebrationInfoInSession({ newReferral: true });
      return false;
    }
  } catch (err) {
    dispatch({
      payload: { newReferral: true },
      type: CELEBRATIONS_ACTIVE_FAILURE,
    });
    setOrUpdateCelebrationInfoInSession({ newReferral: true });
    return false;
  }
};

export const exitCelebrationsReferredBookerFlowAction = () => async (dispatch, getState) => {
  const { referralCode } = getState().celebrations.params;
  // No need to do anything if ReferredBooker flow is already turned off
  if (referralCode === null) {
    return;
  }

  dispatch({ type: CELEBRATION_EXIT_REFERRED_BOOKER_FLOW });

  dispatch(clearWarning([WarningTarget.preCheckOut, WarningTarget.chooseVoyage]));

  removeCelebrationInfoFromSession();
};

export const completeCelebrationProgram = () => async (dispatch, getState) => {
  const state = getState();
  const { params } = state?.celebrations || {};
  dispatch({
    payload: {
      ...params,
      newReferral: true,
    },
    type: COMPLETE_CELEBRATION_PROGRAM,
  });
  removeCelebrationInfoFromSession();
};

export const setOrUpdateCelebrationInfoInSession = (celebrations) => {
  setSessionStorageValue(CELEBRATION_SESSION_DATA, celebrations);
};

export const removeCelebrationInfoFromSession = () => {
  removeSessionStorageValue(CELEBRATION_SESSION_DATA);
};

export const getCelebrationsInfoSession = () => {
  return getSessionStorageValue(CELEBRATION_SESSION_DATA);
};

const fetchReferredBookerPackage = () => async (dispatch, getState) => {
  let state = getState();
  const { packageCode, voyageId } = selectCelebrationsParams(state);
  const sailingData = isEmpty(selectSailingData(state))
    ? await fetchSailings({ params: { packageCode, voyageId } })
    : selectSailingData(state);

  // @Devmani TODO:
  // In the PR: https://bitbucket.org/VirginVoyages/vv-booking-website/pull-requests/5010/diff#Lsrc/ducks/programs/celebrations/actions.jsT218
  // added check `selectedVoyageId !== voyageId`, but it caused a bug that action `CELEBRATION_FETCH_PRODUCT_SUCCESS` may never be called after page refresh
  // PR above has to be reworked
  if (voyageId !== 'undefined' && voyageId) {
    state = getState();
    try {
      const options = makeVoyagesApiDatesOptions(sailingData.embarkDate, sailingData.debarkDate);
      const nextApiPayload = buildFetchVoyageListDataPayload(state, options);
      const prevApiPayload = selectVoyagesApiPayload(state);

      const packages = isEqual(nextApiPayload, prevApiPayload)
        ? selectMainPackages(state)
        : (await fetchVoyages({ payload: nextApiPayload }))?.packages || [];

      const filteredPackages = packages.filter((p) => p.packageCode === packageCode);
      const filteredSailing = filteredPackages.reduce(
        (acc, p) => [...acc, ...p.sailingList.filter((s) => s.id === voyageId)],
        [],
      );
      const mappedPackages = filteredPackages.map((p) => {
        const filteredSailing = p.sailingList.filter((s) => s.id === voyageId);
        return {
          ...p,
          bestPriceSailing: null,
          sailingList: filteredSailing,
          sortedSailings: filteredSailing,
        };
      });

      // filter search results to contain single referred sailing
      dispatch({
        payload: {
          packages: mappedPackages,
          sailings: filteredSailing,
        },
        type: CELEBRATION_FETCH_PRODUCT_SUCCESS,
      });
    } catch (err) {
      dispatch({
        payload: { newReferral: true },
        type: CELEBRATIONS_ACTIVE_FAILURE,
      });
    }
  }
};

const getIsDuplicatedCheck = (celebrations) => {
  const { isExitCelebrations, params } = celebrations || {};
  const { referralCode, voyageId } = params || {};

  const queryParams = getCurrentSearchParams();
  const isRefCodeAlreadyChecked =
    referralCode === queryParams.get('referralCode') &&
    voyageId === queryParams.get('voyageId') &&
    queryParams.get('referralType') === 'CELEBRATIONS';
  const result = isExitCelebrations && isRefCodeAlreadyChecked;

  return result;
};
