import { type TBookingSourcePayload } from '@/ducks/api/selectors';
import { type MnvvContent } from '@/ducks/mnvv/types';
import { env } from '@/environment';
import { simpleFetchWithAuth, simpleFetchWithCmsAuth, type TFetchOptions } from '@/features/api/simpleFetch';
import { type TCurrencyCode } from '@/infra/types/common';
import { type TVoyagesResponse } from '@/infra/types/voyageInfo/package';
import { type TSailingDataResponse } from '@/infra/types/voyageInfo/sailing';

export type TFetchSailingsUrlParams = {
  packageCode: string;
  voyageId: string;
};

export const fetchSailings = (options: TFetchOptions<TFetchSailingsUrlParams>) =>
  simpleFetchWithAuth<TSailingDataResponse, TFetchSailingsUrlParams>(`${env.REST_BFF_URL}/sailings`, options);

export type TFetchVoyagesPayload = TBookingSourcePayload & {
  sailingsGroupByPackage: boolean;
  searchQualifier: {
    accessKeys: string[];
    cabins: {
      guestCounts: { ageCategory: 'Adult'; count: number }[];
    }[];
    currencyCode: TCurrencyCode;
    defaultPackageCodes: string[];
    isDefaultSearch?: boolean;
    preferences: { categoryCode?: string; metaCode?: string }[];
    sailingDateRange: { end: string; start: string }[];
  };
};

export const fetchVoyages = (options: TFetchOptions<undefined, TFetchVoyagesPayload>) =>
  simpleFetchWithAuth<TVoyagesResponse, undefined, TFetchVoyagesPayload>(`${env.REST_BFF_URL}/voyages`, {
    ...options,
    method: 'POST',
  });

export type TFetchAddonsUrlParams = {
  externalId: string;
};

export type TMnvvAddonsResponse = {
  results: MnvvContent[];
};

export const fetchAddons = (options: TFetchOptions<TFetchAddonsUrlParams>) =>
  simpleFetchWithCmsAuth<TMnvvAddonsResponse, TFetchAddonsUrlParams>(`${env.CMS_BASE_URL}/addons`, options);
