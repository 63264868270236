import type {
  ReferralReferralCode,
  ReferralReferralCodeDataResponse,
} from '@/ducks/programs/celebrations/referral/types';

import { FETCH_REFERRAL_REFERRAL_CODE_DATA } from '@/ducks/programs/celebrations/referral/fetch-referral-referral-code-data';
import getApolloClient from '@/features/apollo/getApolloClient';

const defaultResponse: ReferralReferralCodeDataResponse = {
  fetchReferralReferralCode: {
    bookingNumber: undefined,
    voyageId: undefined,
  },
};

export const fetchReferralReferralCodeData = async (
  params: ReferralReferralCode,
): Promise<ReferralReferralCodeDataResponse> => {
  const { referralCode } = params;
  if (!referralCode) throw new TypeError(`Empty referralCode: ${referralCode}`);

  try {
    const client = getApolloClient();

    const { data } = await client.mutate<ReferralReferralCodeDataResponse>({
      mutation: FETCH_REFERRAL_REFERRAL_CODE_DATA,
      variables: {
        referralCode,
      },
    });

    return data ?? defaultResponse;
  } catch (error) {
    console.error('Error linking referral data:', error);
    throw error;
  }
};
