import { env } from '@/environment';
import { TokenType, withAuthHeader } from '@/helpers/api/tokens/helpers/withToken';
import sharedCall from '@/helpers/sharedCall';

import { api } from './core';
import buildInvoiceDetailBody from './mappers/req/InvoiceDetail';
import isValidCalculateInvoice from './validators/calculateInvoice';

export const fetchCMSComponent = (url, noAuth = false) =>
  api.get(url, {
    baseURL: env.CMS_COMPONENT_BASE_URL,
    noAuth,
  });

export const fetchCMSComponentForBook = (url) => api.get(url, { baseURL: env.CMS_COMPONENT_BASE_URL_BOOK });

export const fetchReferralCode = (reservationNumber) =>
  api.get(`/referral/booking/${reservationNumber}`, {
    baseURL: env.SEAWARE_BASE_URL,
  });

export const checkReferralEligibility = sharedCall((params) =>
  api.post('/referral/eligibility', params, {
    baseURL: env.SEAWARE_BASE_URL,
  }),
);

export const fetchSignedFields = withAuthHeader(TokenType.guest, (authHeaders, params) =>
  api.post('/signature', params, { headers: authHeaders }),
);

export const initiateUpliftPayment = withAuthHeader(TokenType.guest, (authHeaders, params) =>
  api.post('/initiatepayment', params, {
    baseURL: env.PAYMENT_URL,
    headers: authHeaders,
  }),
);

export const postPaymentResponse = withAuthHeader(TokenType.guest, (authHeaders, params) =>
  api.post('/reservation/pay', params, { headers: authHeaders }),
);

// TODO: In all api.get call here need to pass the parameters as the config.params field, and not form the url manually!

export const fetchDashboardData = (reservationNumber, processingId) => {
  const params = { reservationNumber, ...(processingId ? { processingId } : undefined) };
  return api.get('/voyagedashboard', { params });
};

export const fetchCabinDetailsData = (chosenCategoryCode, cabinMetaCode, isCabinAccessible) =>
  api.get(
    `/cabins/${chosenCategoryCode}/details?cabinMetaCode=${cabinMetaCode}${
      isCabinAccessible ? `&isAccessible=${isCabinAccessible}` : ''
    }`,
  );

export const fetchCaluclateInvoiceData = sharedCall(
  ({
    cabins,
    chosenSubCategoryCode,
    currencyCode,
    externalRefId,
    fareClass,
    isCabinAccessible,
    isServiceChargeEnabled,
    metaType,
    promoCode,
    sailors,
    voyageId,
  }) =>
    api.post(
      '/calculateinvoice',
      buildInvoiceDetailBody(
        voyageId,
        chosenSubCategoryCode,
        sailors,
        cabins,
        currencyCode,
        isCabinAccessible,
        metaType,
        promoCode,
        externalRefId,
        fareClass,
        isServiceChargeEnabled,
      ),
      { validator: isValidCalculateInvoice },
    ),
  { timeout: 90000 },
);

export const cabinHold = (cabinDetails) => api.post('/cabin/hold', cabinDetails);

export const cabinUnhold = (cabinDetails) => api.post('/cabin/unhold', cabinDetails);

export const fetchWhatsIncludedData = () => api.get('/whatsIncluded');

export const validateAgency = (agentId, includeAgentDetails = false) =>
  api.get(`/reservations/agentdetail/validation?agentId=${agentId}&includeAgentDetails=${includeAgentDetails}`, {
    baseURL: env.DXP_CORE_BASE_URL,
  });

export const bookNow = sharedCall((payload) => api.post('/booknow', payload));

export const shortenUrl = sharedCall((shareUrl) => api.get(`/api/shorten?url=${encodeURIComponent(shareUrl)}`));

export const fetchConfirmationTodoList = (reservationNumber, isVip, clientTransactionId) =>
  api.get('/confirmationPage/landing', { params: { clientTransactionId, isVip, reservationNumber } });
